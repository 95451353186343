<template>
    <v-card :elevation="5">
        <v-card-title>
            Player {{ this.$route.params.player }} | Promotions
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="promotions"
            :search="search"
            :loading="loading"
        >
          <template v-slot:top>
              <v-toolbar flat>
                  <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      :to="{ name: 'players.addPromotionPlayer' }"
                  >
                      Adds new Promotion
                  </v-btn>
              </v-toolbar>
          </template>
          <template v-slot:item.game_promotion.promotion.status="{ item }">
              <promotion-status-indicator :status="item.game_promotion.promotion.status"></promotion-status-indicator>
          </template>
          <template v-slot:item.game_promotion.promotion.begins_at="{ item }">
              <span>{{ new Date(item.game_promotion.promotion.begins_at).toLocaleDateString("nl",{year:"numeric",month:"2-digit", day:"2-digit"}) }}</span>
          </template>
          <template v-slot:item.game_promotion.promotion.expires_at="{ item }">
              <span>{{ new Date(item.game_promotion.promotion.expires_at).toLocaleDateString("nl",{year:"numeric",month:"2-digit", day:"2-digit"}) }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
              <v-icon
                  small
                  class="mr-2"
                  @click="details(item)"
              >
                  mdi-eye
              </v-icon>
          </template>
        </v-data-table>
    </v-card>
</template>

<script>
import PromotionStatusIndicator from '@/components/PromotionStatusIndicator'

export default {
  components: { PromotionStatusIndicator },
  data () {
    return {
      promotions: [],
      loading: true,
      search: '',
      headers: [
        {
          value: 'game_promotion.promotion.promotion_type',
          text: 'Promotion Type'
        },
        {
          value: 'game_promotion.promotion.status',
          text: 'Status'
        },
        {
          value: 'game_promotion.promotion.title',
          text: 'Promotion Code'
        },
        {
          value: 'game_promotion.promotion.priority',
          text: 'Priority'
        },
        {
          value: 'game_promotion.promotion.description',
          text: 'Description'
        },
        {
          value: 'game_promotion.promotion.begins_at',
          text: 'Starting Date'
        },
        {
          value: 'game_promotion.promotion.expires_at',
          text: 'Expiration Date'
        },
        {
          value: 'game_promotion.promotion.promotion_amount_used',
          text: 'Promotion Amount Used €'
        },
        {
          value: 'promo_code',
          text: 'Session ID'
        },
        {
          value: 'uuid',
          text: 'UUID'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },

  mounted () {
    this.$axios.get(`api/players/${this.$route.params.player}/promotionsAll`).then(response => {
      this.promotions = response.data
      this.loading = false
    })
  },

  methods: {
    details (item) {
      this.$router.push({
        name: 'promotions.show',
        params: { promotion: item.game_promotion.promotion.id, contractId: this.$route.params.player }
      })
    }
  }
}
</script>
