<template>
    <v-card :elevation="5">
        <v-card-title>
            Players
            <v-spacer></v-spacer>

            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="players"
            :search="search"
            :loading="loading"
        >
          <template v-slot:top>
                <v-toolbar flat>
                    <CreatePlayer
                       @playerCreated="pushPlayer"
                     />
                     <!--
                     <v-file-input
                        type="file"
                        id="file"
                        show-size
                        truncate-length="50"
                        accept="text/csv"
                        label="player csv file"
                      ></v-file-input>
                      -->
                </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="promotions(item)"
                >
                    mdi-eye
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import CreatePlayer from './CreatePlayer'

export default {
  components: {
    CreatePlayer
  },
  data () {
    return {
      players: [],
      loading: true,
      search: '',
      headers: [
        {
          text: 'Contract Number',
          align: 'start',
          sortable: true,
          value: 'contract_number'
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Username',
          align: 'start',
          sortable: true,
          value: 'username'
        },
        {
          text: 'actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },

  mounted () {
    this.$axios.get('api/players').then(response => {
      this.players = response.data
      this.loading = false
    })
  },

  methods: {
    pushPlayer (player) {
      this.players.unshift(player)
    },
    promotions (item) {
      this.$router.push({
        name: 'players.promotions',
        params: { player: item.contract_number }
      })
    }
  }
}
</script>
