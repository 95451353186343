<template>
    <v-card :elevation="5">
        <v-card-title>
            Promotions
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="promotions"
            :search="search"
            :loading="loading"
        >
            <template v-slot:item.actions="{ item }">
                <!-- <v-icon
                    small
                    class="mr-2"
                    @click="associate(item)"
                >
                    mdi-eye
                </v-icon> -->
                <v-dialog
                    v-model="getTokenDialog"
                    persistent
                    max-width="600px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            ASSOCIATE PLAYER
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">ASSOCIATE PLAYER</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                Are you sure that you want to associate this player <br />to the selected Promo {{ item.title }} ?
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="getTokenDialog = false"
                            >
                                Close
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="associate(item)"
                            >
                                associate
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>

export default {
  data () {
    return {
      getTokenDialog: false,
      promotions: [],
      loading: true,
      search: '',
      headers: [
        {
          value: 'promotion_type',
          text: 'Promotion Type'
        },
        {
          value: 'status',
          text: 'Status'
        },
        {
          value: 'title',
          text: 'Promotion'
        },
        {
          value: 'priority',
          text: 'Priority'
        },
        {
          value: 'description',
          text: 'Description'
        },
        {
          value: 'begins_at',
          text: 'Starting Date'
        },
        {
          value: 'expires_at',
          text: 'Expiration Date'
        },
        {
          value: 'win_cap',
          text: 'Win Cap €'
        },
        {
          value: 'promotion_cap',
          text: 'Promotion Cap €'
        },
        {
          value: 'promotion_amount_used',
          text: 'Promotion Amount Used €'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },

  mounted () {
    this.$axios.get(`api/players/${this.$route.params.player}/promotionsToAssociate`).then(response => {
      this.promotions = response.data
      this.loading = false
    })
  },

  methods: {
    associate (item) {
      this.$axios.put(`api/players/${this.$route.params.player}/promotions/${item.id}/associate`).then(response => {
        this.$router.push({
          name: 'players.promotions',
          params: { player: this.$route.params.player }
        })
      })
    },
    update (item) {
      this.$router.push({
        name: 'promotions.update',
        params: { promotion: item.id }
      })
    }
  }
}
</script>
