var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":5}},[_c('v-card-title',[_vm._v(" Player "+_vm._s(this.$route.params.player)+" | Promotions "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.promotions,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","to":{ name: 'players.addPromotionPlayer' }}},[_vm._v(" Adds new Promotion ")])],1)]},proxy:true},{key:"item.game_promotion.promotion.status",fn:function(ref){
var item = ref.item;
return [_c('promotion-status-indicator',{attrs:{"status":item.game_promotion.promotion.status}})]}},{key:"item.game_promotion.promotion.begins_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.game_promotion.promotion.begins_at).toLocaleDateString("nl",{year:"numeric",month:"2-digit", day:"2-digit"})))])]}},{key:"item.game_promotion.promotion.expires_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.game_promotion.promotion.expires_at).toLocaleDateString("nl",{year:"numeric",month:"2-digit", day:"2-digit"})))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.details(item)}}},[_vm._v(" mdi-eye ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }